@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

html,
body {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}
.Urbanist{
  font-family: "Urbanist", sans-serif;

}
.Nunito-font {
  font-family: "Nunito Sans", sans-serif;
}
.dm-font{
  font-family: "DM Sans", sans-serif;

}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito Sans", sans-serif;
}

a,
span,
b,
strong, label
button {
  font-family: "Nunito Sans", sans-serif;
}
.bg-white{
  background-color: #fff !important;
}

.bg-img{
  background: linear-gradient(45deg, rgb(0 0 0 / 90%), rgb(79 71 151 / 85%)),  url(/src/assets/images/ai-bg.jpg);
  background-position: center;
  background-size: cover;
}
body{
  background:#eef2ff;
}

.nth-color tr:nth-child(even) {
  background: #f3fafb;
}


@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-0);
  }
}

@keyframes slide-out-left {
  0% {
    transform: translateX(-0);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-slide-in-right {
  animation: slide-in-right 0.5s forwards;
}

.animate-slide-out-left {
  animation: slide-out-left   0.5s forwards;
}
.inner-page-bg-input input, select, textarea{
  background-color: #fafbff !important;
  box-shadow: none !important;
  border: 1px solid #e1e1e1 !important;
  margin-top: 3px;
}
.add-ai-worker-line .source-box {
  border-color:#f28dff;
}
.add-destination-line .source-box {
  border-color: #ffdbaa;
}
